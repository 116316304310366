import React from "react";
import { Redirect } from "react-router-dom";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { useGetUserProfile } from "hooks/User";

const Profile = () => {
  const { profileData, loading } = useGetUserProfile();

  if (loading) {
    return <LoaderCentered />;
  }

  if (!profileData?.username) {
    return null;
  }

  // Redirect to user profile route
  return <Redirect to={`/@${profileData.username}`} />;
};

export default Profile;
